import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import LineReveal from "../../../../components/Text/LineReveal/LineReveal";
import { Container, Content, Gradient } from "./DesktopDescription.styles";

function DesktopDescription({ description, content, title }) {
  const [isOverflowing, setisOverflowing] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
      setisOverflowing(true);
    }
  }, [isOverflowing, description]);

  return (
    <Container>
      <AnimatePresence exitBeforeEnter>
        <LineReveal
          key={`Title for: ${description}`}
          renderAs="h4"
          text={title}
        />
      </AnimatePresence>
      <Content ref={contentRef}>
        <AnimatePresence exitBeforeEnter>
          <LineReveal key={description} text={content[description]} />
        </AnimatePresence>
        <Gradient isOverflowing={isOverflowing} />
      </Content>
    </Container>
  );
}

export default React.memo(DesktopDescription);
