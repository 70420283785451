import React, { useEffect, useRef } from "react";
import useViewportSize from "../../../hooks/useViewportSize";
import * as S from "./BottleAnimation.styles";

function BottleAnimation({ url, visible, setShouldShowCanvas, ...rest }) {
  const urlWithoutEN = url.replace("/en/", "");

  const canvasRef = useRef<HTMLCanvasElement>();
  const elementScrollY = useRef<HTMLDivElement>();

  const frameCount = 60;
  const { size } = useViewportSize();

  const currentFrame = (index) =>
    `https://piwnicepoltorak.pl/animations/${urlWithoutEN}/${index
      .toString()
      .padStart(4, "0")}.jpg`;

  const preloadImages = async () => {
    try {
      const promises = [];

      for (let i = 1; i < frameCount; i++) {
        const img = new Image();
        const promise = new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        img.src = currentFrame(i);
        promises.push(promise);
      }

      await Promise.all(promises);
      setShouldShowCanvas(true);
    } catch (error) {
      setShouldShowCanvas(false);
    }
  };

  useEffect(() => {
    preloadImages();
    const context = canvasRef.current.getContext("2d");
    let frameIndex = 1;
    let animationFrameCount = 60;
    const img = new Image();
    img.src = currentFrame(1);
    canvasRef.current.width = 1200;
    canvasRef.current.height = 1200;
    img.onload = function () {
      context.drawImage(img, 0, 0);
    };

    const updateImage = (index) => {
      img.src = currentFrame(index);
      context.drawImage(img, 0, 0);
    };

    const getFrame = () => {
      if (elementScrollY.current !== undefined) {
        const amountScrolled =
          elementScrollY.current.getBoundingClientRect().top * -1;
        const scrollFraction =
          Math.abs(amountScrolled) / window.innerHeight / 5;

        const index = Math.min(
          animationFrameCount - 1,
          Math.ceil(scrollFraction * animationFrameCount)
        );
        frameIndex = index > 0 ? index : 1;
      }
      requestAnimationFrame(() => updateImage(Math.ceil(frameIndex)));
    };

    window.addEventListener("scroll", getFrame, { passive: true });

    return () => {
      window.removeEventListener("scroll", getFrame);
    };
  }, []);

  return (
    <>
      <div ref={elementScrollY} />
      {setShouldShowCanvas && (
        <S.Canvas
          key={urlWithoutEN}
          vh={size.height}
          visible={visible}
          ref={canvasRef}
          {...rest}
        />
      )}
    </>
  );
}

export default BottleAnimation;
