import React, { FC } from "react";
import { m, useAnimation } from "framer-motion";
import { Link } from "gatsby";

import { circleVariants, arrowVariants } from "./ButtonIcon.animation";

import * as Styled from "./ButtonIcon.styles";

export interface ButtonIconProps {
  url?: string;
  inView?: boolean;
}

const ButtonIcon: FC<ButtonIconProps> = ({ url, inView, ...rest }) => {
  const controls = useAnimation();

  // Animations
  async function HoverAnim() {
    await controls.start("hover");
  }
  async function IntroAnim() {
    await controls.start("animate");
  }

  // Fire Inital Animation
  inView && IntroAnim();

  return (
    <Link to={url} {...rest}>
      <Styled.Svg
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        whileHover={{ scale: 1.25 }}
        whileTap={{ scale: 0.9 }}
        onHoverStart={() => HoverAnim()}
      >
        <Styled.Circle
          d="M0.5,16a15.5,15.5 0 1,0 31,0a15.5,15.5 0 1,0 -31,0"
          animate={controls}
          variants={circleVariants}
          initial="initial"
        />

        <m.path
          d="M11 16.5H20M20 16.5L16.5 13M20 16.5L16.5 20"
          stroke-linecap="round"
          stroke-linejoin="round"
          variants={arrowVariants}
          initial="initial"
          animate={controls}
        />
      </Styled.Svg>
    </Link>
  );
};

export default ButtonIcon;
