import React, { FC } from "react";
import { Link } from "gatsby";

import LineReveal from "../Text/LineReveal/LineReveal";

import * as S from "./Button.styles";
import { useInView } from "react-intersection-observer";

export interface ButtonProps {
  text?: string;
  goldLine?: boolean;
  reverse?: boolean;
  url?: string;
  style?: any;
  isExternal?: boolean;
}

const Button: FC<ButtonProps> = ({
  goldLine,
  text,
  reverse,
  url = "/",
  isExternal = false,
  ...rest
}) => {
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true,
  });
  return (
    <S.StyledButton ref={ref} {...rest}>
      {goldLine && (
        <S.GoldLine
          inView={inView}
          reverse={reverse}
          initial={{ scaleY: 0, opacity: 0 }}
          animate={{ scaleY: inView ? 1 : 0, opacity: inView ? 1 : 0 }}
          transition={{ duration: 1.2, ease: "easeOut" }}
        />
      )}
      {text && !isExternal && (
        <Link to={url} style={{ whiteSpace: "nowrap" }}>
          <LineReveal
            triggerOnce
            renderAs="p"
            text={text}
            style={{ whiteSpace: "nowrap" }}
          />
        </Link>
      )}
      {text && isExternal && (
        <a href={url} target="_blank" style={{ whiteSpace: "nowrap" }}>
          <LineReveal
            triggerOnce
            renderAs="p"
            text={text}
            style={{ whiteSpace: "nowrap" }}
          />
        </a>
      )}

      <S.Icon url={url} inView={inView} reverse={reverse} />
    </S.StyledButton>
  );
};
export default Button;
