import styled, { css } from "styled-components";
import { media } from "../../../../utils/styled/responsive";

interface GradientProps {
  isOverflowing: boolean;
}

export const Container = styled.div`
  display: none;

  ${media.tablet} {
    display: grid;
    position: fixed;
    gap: 16px;
    left: 64px;
    bottom: 15vh;
    width: 45vw;
  }

  ${media.desktop} {
    gap: 24px;
    width: 30vw;
    right: 80px;
    left: unset;
    bottom: 80px;
  }
`;

export const Content = styled.div`
  position: relative;

  ${media.tablet} {
    max-height: 30vh;
    overflow-y: scroll;
    width: 45vw;
    & .spacer {
      height: 0.5em;
    }
  }

  ${media.desktop} {
    max-height: 35vh;
    width: 30vw;
  }
`;

export const Gradient = styled.div<GradientProps>`
  pointer-events: none;
  height: 50px;
  position: sticky;
  bottom: 0;
  width: auto;
  display: none;
  ${(props) =>
    props.isOverflowing &&
    css`
      opacity: 1;
      display: initial;
    `};

  ${media.tablet} {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  ${media.desktop} {
    background: linear-gradient(
      0deg,
      rgba(26, 26, 26, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* width: 30vw; */
  }
`;
