import React, { useEffect, useState } from "react";
import DesktopDescription from "./DesktopDescription/DesktopDescription";

function Description({ data, description }) {
  const [currentTitle, setCurrentTitle] = useState("");

  // Destructure with default empty objects to prevent null reference errors
  const {
    yearDescription = "",
    varietiesDescription = "",
    productionProcess = "",
    wineDescription = "",
    parametersDescription = "",
  } = data?.contentfulProduct || {};

  const titlesData = data?.allContentfulProductPageDescriptionMenu?.edges || [];

  useEffect(() => {
    const getTitle = () => {
      const foundTitle = titlesData.find((obj) => obj.node.key === description);
      if (foundTitle) {
        setCurrentTitle(foundTitle.node.title);
      }
    };
    getTitle();
  }, [description, titlesData]);

  const content = {
    wineDescription: wineDescription?.wineDescription || "",
    yearDescription: yearDescription?.yearDescription || "",
    varietiesDescription: varietiesDescription?.varietiesDescription || "",
    productionProcess: productionProcess?.productionProcess || "",
    parametersDescription: parametersDescription?.parametersDescription || "",
  };

  return (
    <>
      <DesktopDescription
        description={description}
        content={content}
        title={currentTitle}
      />
    </>
  );
}

export default React.memo(Description);
