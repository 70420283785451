import { m } from "framer-motion";
import styled from "styled-components";
import { media } from "../../../../utils/styled/responsive";

export const Content = styled(m.div)`
  width: 100vw;
  height: fit-content;
  background: black;
  z-index: 10;
  display: grid;
  gap: 80px;
  padding: 160px 16px 160px;
  filter: drop-shadow(0 0 32px rgba(0, 0, 0, 0.75));

  & .spacer {
    height: 0.2em;
  }

  ${media.tablet} {
    display: none;
  }
`;

export const Row = styled.div`
  display: grid;
  gap: 16px;
`;

export const BottomNav = styled.div`
  display: flex;
  justify-content: space-between;
`;
