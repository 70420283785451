import styled from "styled-components";
import { m } from "framer-motion";

export const Svg = styled(m.svg)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  transform-origin: center !important;
  transform: scaleY(-1);
  stroke: white;
  will-change: opacity, transform, path-length;
`;

export const Circle = styled(m.path)`
  transform-origin: center !important;
`;
