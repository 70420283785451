import styled from "styled-components";
import { m } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";
import { media } from "../../utils/styled/responsive";

interface BottleImageProps {
  visible: number;
}

interface ContainerProps {
  scrollHeight: string;
}

export const Container = styled(m.div)<ContainerProps>`
  position: sticky;
  height: ${({ scrollHeight }) => scrollHeight};
`;

export const BottomNav = styled.div`
  display: none;

  ${media.tablet} {
    position: fixed;
    bottom: 80vh;
    left: 64px;
    display: grid;
    gap: 40px;
    grid-auto-flow: column;
  }

  ${media.desktop} {
    position: fixed;
    bottom: 80px;
    left: 80px;
    display: grid;
    gap: 40px;
    grid-auto-flow: column;
  }
`;

export const BottleImage = styled(GatsbyImage)<BottleImageProps>`
  display: ${({ visible }) => (visible > 0 ? "inherit" : "none")};
  position: fixed;
  height: 55vh;
  left: 50%;
  transform: translateX(-50%);
  top: 96px;

  ${media.tablet} {
    left: 75%;
    top: unset;
    bottom: 15vh;
  }

  ${media.desktop} {
    left: 50%;
    height: 75vh;
    bottom: 40px;
  }
`;

export const Background = styled.div`
  position: fixed;
  width: 50vw;
  height: 150vh;
  top: 0;
  right: 0;

  ${media.tablet} {
    width: 25vw;
  }

  ${media.desktop} {
    width: 50vw;
  }
`;
