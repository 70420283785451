import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BackgroundMorph from "../../components/BackgroundMorph/BackgroundMorph";
import FadeInOut from "../../components/Animations/FadeInOut/FadeInOut";
import { NavbarContext } from "../../context/navbarContext";

import ContentSelector from "./ContentSelector/ContentSelector";
import Description from "./Description/index";
import MobileDescription from "./Description/MobileDescription/MobileDescription";
import LocalesButton from "./LocalesButton/LocalesButton";

import BottleAnimation from "./BottleAnimation/BottleAnimation";

import {
  Background,
  BottleImage,
  BottomNav,
  Container,
} from "./ProductPage.styles";
import WineHeading from "./WineHeading/WineHeading";
import { sharedValues } from "../../utils/styled/sharedValues";
import Price from "./Price/Price";
import { useLanguage } from "../../hooks/useLanguage";

function ProductPage({ data }) {
  const { setNavbar } = useContext(NavbarContext);
  const [description, setDescription] = useState("wineDescription");
  const [shouldShowCanvas, setShouldShowCanvas] = useState(false);
  const [isBackgroundReady, setIsBackgroundReady] = useState(false);

  // Destructured Data
  const { url, image, priceNumber, shopUrl } = data.contentfulProduct;

  const [language] = useLanguage();
  const wineUnavailable =
    language === "en" ? "Wine unavailable" : "Produkt niedostępny";

  useEffect(() => {
    setNavbar({ shouldHide: false });
    window.addEventListener("DOMContentLoaded", () => {
      console.log("DOM fully loaded and parsed");
    });
    const timeoutId = setTimeout(() => setIsBackgroundReady(true), 1200);
    return () => {
      clearTimeout(timeoutId);
      setNavbar({ shouldHide: true });
    };
  }, []);

  return (
    <>
      <FadeInOut>
        <Container scrollHeight={shouldShowCanvas ? "600vh" : "100vh"}>
          <Background>
            <BackgroundMorph
              isOpen={true}
              instant
              fill={sharedValues.colors.black[2]}
              transformOrigin="right"
              duration={1.2}
            />
          </Background>

          <BottleAnimation
            visible={shouldShowCanvas && isBackgroundReady}
            setShouldShowCanvas={setShouldShowCanvas}
            url={url}
          />

          <BottleImage
            image={getImage(image)}
            alt=""
            objectFit={"contain"}
            visible={shouldShowCanvas ? 0 : 1}
          />

          <ContentSelector
            data={data}
            description={description}
            setDescription={setDescription}
          />
          <WineHeading data={data} />
          <Description data={data} description={description} />
          <BottomNav>
            {priceNumber && (
              <Price shopUrl={shopUrl} price={priceNumber.toFixed(2) + "PLN"} />
            )}
            {!priceNumber && <Price price={wineUnavailable} />}

            <LocalesButton />
          </BottomNav>
        </Container>
        <MobileDescription
          data={data}
          priceNumber={priceNumber}
          wineUnavailable={wineUnavailable}
        />
      </FadeInOut>
    </>
  );
}

export default React.memo(ProductPage);

export const data = graphql`
  query ProductData($slug: String, $locale: String) {
    contentfulProduct(url: { eq: $slug }, node_locale: { eq: $locale }) {
      image {
        gatsbyImageData(formats: [WEBP, AUTO], placeholder: BLURRED)
      }
      priceNumber
      shopUrl
      wineName
      year
      url
      node_locale
      shortDescription
      varietiesDescription {
        varietiesDescription
      }
      wineDescription {
        wineDescription
      }
      productionProcess {
        productionProcess
      }
      yearDescription {
        yearDescription
      }
      parametersDescription {
        parametersDescription
      }
    }
    allContentfulProductPageDescriptionMenu(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: createdAt }
    ) {
      edges {
        node {
          title
          key
        }
      }
    }
  }
`;
