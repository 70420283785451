import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import LineReveal from "../../../components/Text/LineReveal/LineReveal";
import { useLanguage } from "../../../hooks/useLanguage";

function LocalesButton() {
  const [language] = useLanguage();

  return (
    <Button to={language === "pl" ? "/lokale/" : "/en/lokale/"}>
      <LineReveal text={language === "pl" ? "Lokale" : "Restaurants"} />
      <svg viewBox="0 0 15 19" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.6079 7.73656C14.6079 3.88711 11.4079 0.787109 7.43425 0.787109C3.46062 0.787109 0.26062 3.88711 0.26062 7.73656C0.26062 11.586 7.43425 18.7739 7.43425 18.7739C7.43425 18.7739 14.6079 11.586 14.6079 7.73656ZM4.09359 7.6003C4.09359 5.82887 5.60568 4.36403 7.43425 4.36403C9.26282 4.36403 10.7749 5.7948 10.7749 7.6003C10.7749 9.37173 9.29799 10.8366 7.43425 10.8366C5.60568 10.8366 4.09359 9.37173 4.09359 7.6003Z"
          fill="#868686"
        />
      </svg>
    </Button>
  );
}

export default LocalesButton;

export const Button = styled(Link)`
  cursor: pointer;
  color: #868686;
  grid-auto-flow: column;
  display: grid;
  gap: 24px;
  align-items: center;
  transition: 0.2s ease-in-out;
  & :hover {
    color: white;
  }

  & svg {
    height: 1em;
    & path {
      fill: #868686;
      &:hover {
        fill: white;
      }
    }
  }
`;
