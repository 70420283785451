import styled, { css } from "styled-components";
import { m } from "framer-motion";

import { media } from "../../utils/styled/responsive";
import ButtonIcon from "../ButtonIcon/ButtonIcon";

interface GoldLineProps {
  inView: boolean;
  reverse: boolean;
}
interface IconProps {
  reverse: boolean;
}

export const StyledButton = styled(m.div)`
  position: relative;
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  cursor: pointer;

  gap: 12px;

  ${media.tablet} {
    gap: 24px;
  }
`;

export const Icon = styled(ButtonIcon)<IconProps>`
  ${({ reverse }) =>
    reverse &&
    css`
      transform: scaleY(-1) rotate(180deg);
      grid-column: 1;
    `}
`;
export const GoldLine = styled(m.div)<GoldLineProps>`
  width: 2px;
  height: 24px;
  background: ${({ theme }) => theme.gold};
  transform-origin: bottom;
  cursor: default;
  will-change: transform;

  ${({ reverse }) =>
    reverse &&
    css`
      transform: scaleY(-1) rotate(180deg);
      grid-column: 3;
    `}

  ${media.tablet} {
    height: 32px;
  }

  ${media.desktop} {
    height: 40px;
  }
`;
