import { AnimatePresence } from "framer-motion";
import React from "react";
import { Switch, SwitchItem } from "./ContentSelector.styles";

function ContentSelector({ data, description, setDescription }) {
  const titlesArr = data.allContentfulProductPageDescriptionMenu.edges;

  return (
    <Switch>
      <AnimatePresence>
        {titlesArr.map(({ node }, index) => (
          <SwitchItem
            delay={0.8 + index * 0.2}
            key={node.title + index}
            onClick={() => setDescription(node.key)}
            text={node.title}
            className={description === node.key && "active"}
          />
        ))}
      </AnimatePresence>
    </Switch>
  );
}

export default React.memo(ContentSelector);
