import styled from "styled-components";
import { media } from "../../../utils/styled/responsive";

interface CanvasProps {
  visible?: number;
  vh: number;
}

export const Canvas = styled.canvas<CanvasProps>`
  position: fixed;
  display: ${({ visible }) => (visible ? "inherit" : "none")};

  height: ${({ vh }) => (vh ? `${vh * 0.55}px` : "55vh")};
  transition: 0.2s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  top: 96px;

  ${media.tablet} {
    left: 75%;
    top: unset;
    bottom: 15vh;
  }

  ${media.desktop} {
    left: 50%;
    height: ${({ vh }) => (vh ? `${vh * 0.75}px` : "75vh")};

    bottom: 40px;
  }
`;

export const MeasureScroll = styled.div`
  position: fixed;
  left: 0;
  top: 0;
`;
