import styled from "styled-components";
import LineReveal from "../../../components/Text/LineReveal/LineReveal";
import { media } from "../../../utils/styled/responsive";

interface SwitchItemProps {
  onClick?: () => void;
  className?: string;
}

export const Switch = styled.div`
  display: none;
  pointer-events: none;

  ${media.tablet} {
    position: fixed;
    display: grid;
    text-align: left;
    gap: 24px;
    bottom: 5vh;
    left: 64px;
    grid-template-columns: repeat(5, auto);
    width: auto;
    & p {
      color: ${({ theme }) => theme.white[2]};
      line-height: 1.8;
      cursor: pointer;
    }
  }

  ${media.desktop} {
    grid-template-columns: unset;
    gap: unset;
    right: 80px;
    justify-content: end;
    text-align: right;
    top: 200px;
    left: unset;
    bottom: unset;
  }
`;

export const SwitchItem = styled(LineReveal)<SwitchItemProps>`
  cursor: pointer;
  pointer-events: all;
  transition: color 0.2s ease-in-out;

  &:hover p {
    color: white !important;
  }
  & .active {
    color: white !important;
  }
`;
