export const circleVariants = {
  initial: { pathLength: 0, rotate: 180, opacity: 0 },
  animate: {
    pathLength: 1,
    rotate: 0,
    opacity: 1,
    transition: {
      duration: 1.2,
    },
  },
};

export const arrowVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    x: [-20, 20, -20, 0],
    transition: {
      duration: 1.2,
      times: [0, 0.5, 0.5, 1],
      delay: 0.6,
    },
  },
  hover: {
    opacity: 1,
    x: [null, 20, -20, 0],
    transition: { times: [0, 0.5, 0.5, 1] },
  },
};
