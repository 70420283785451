import React from "react";
import LineReveal from "../../../../components/Text/LineReveal/LineReveal";
import LocalesButton from "../../LocalesButton/LocalesButton";
import Price from "../../Price/Price";
import { BottomNav, Content, Row } from "./MobileDescription.styles";

function MobileDescription({ data, priceNumber, wineUnavailable }) {
  // Destructure with optional chaining and default values
  const {
    yearDescription = {},
    varietiesDescription = {},
    productionProcess = {},
    wineDescription = {},
    parametersDescription = {},
    node_locale,
    shopUrl,
  } = data?.contentfulProduct || {};

  const isPL = node_locale === "pl";

  return (
    <Content>
      <Row>
        <LineReveal
          renderAs="h4"
          text={isPL ? "Profil Sensoryczny" : "Sensory Profile"}
        />
        <LineReveal longText text={wineDescription?.wineDescription || ""} />
      </Row>
      <Row>
        <LineReveal renderAs="h4" text={isPL ? "Rocznik" : "Wine Vintage"} />
        <LineReveal longText text={yearDescription?.yearDescription || ""} />
      </Row>
      <Row>
        <LineReveal renderAs="h4" text={isPL ? "Odmiany" : "Grape varieties"} />
        <LineReveal
          longText
          text={varietiesDescription?.varietiesDescription || ""}
        />
      </Row>
      <Row>
        <LineReveal
          renderAs="h4"
          text={isPL ? "Proces Technologiczny" : "Technological process"}
        />
        <LineReveal
          longText
          text={productionProcess?.productionProcess || ""}
        />
      </Row>
      <Row>
        <LineReveal renderAs="h4" text={isPL ? "Parametry" : "Parameters"} />
        <LineReveal
          longText
          text={parametersDescription?.parametersDescription || ""}
        />
      </Row>
      <BottomNav>
        {priceNumber != null && (
          <Price shopUrl={shopUrl} price={`${priceNumber.toFixed(2)}PLN`} />
        )}
        {priceNumber == null && <Price price={wineUnavailable} />}
        <LocalesButton />
      </BottomNav>
    </Content>
  );
}

export default React.memo(MobileDescription);
