import React from "react";
import LineReveal from "../../../components/Text/LineReveal/LineReveal";
import { Header, Inner, Heading, Year } from "./WineHeading.styles";

function WineHeading({ data }) {
  const { wineName, shortDescription, year } = data.contentfulProduct;
  return (
    <Header>
      <Inner>
        <Heading renderAs="h1" text={wineName} />
        <LineReveal delay={0.6} renderAs="small" text={shortDescription} />
        <Year>
          <LineReveal delay={0.2} renderAs="small" cName="year" text="20" />
          <LineReveal delay={0.4} renderAs="small" cName="year" text={year} />
        </Year>
      </Inner>
    </Header>
  );
}

export default React.memo(WineHeading);
