import styled from "styled-components";
import LineReveal from "../../../components/Text/LineReveal/LineReveal";
import { media } from "../../../utils/styled/responsive";

export const Header = styled.div`
  position: fixed;
  display: inline;
  top: calc(96px + 55vh + 24px);
  left: 50vw;
  transform: translateX(-50%);

  ${media.tablet} {
    transform: unset;
    top: 35vh;
    left: 64px;
    right: 64px;
    place-items: unset;
  }

  ${media.desktop} {
    top: 60vh;
    transform: translateY(-50%);
    width: auto;
    max-width: 35vw;
  }
`;

export const Inner = styled.div`
  position: relative;
  width: fit-content;
  max-width: 100%;
  text-align: center;

  ${media.tablet} {
    text-align: initial;
  }
`;

export const Heading = styled(LineReveal)`
  width: min-content;
  & h1 {
    font-size: 32px;
    line-height: 40px;
  }

  ${media.tablet} {
    & h1 {
      font-size: 52px;
      line-height: 64px;
    }
  }

  ${media.desktop} {
    & h1 {
      font-size: 64px;
      line-height: 80px;
    }
  }
`;

export const Year = styled.div`
  position: absolute;
  right: -24px;
  top: -24px;

  ${media.desktop} {
    right: -40px;
    top: -24px;
  }
`;
