import React, { FC } from "react";
import { useInView } from "react-intersection-observer";
import Button from "../../../components/Button/Button";

import LineReveal from "../../../components/Text/LineReveal/LineReveal";

import { Container, GoldLine } from "./Price.styles";

export interface PriceProps {
  price?: any;
  shopUrl?: string;
}

const Price: FC<PriceProps> = ({ price, shopUrl, ...rest }) => {
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true,
  });

  return (
    <Container ref={ref} {...rest}>
      <GoldLine
        initial={{ scaleY: 0, opacity: 0 }}
        animate={{ scaleY: inView ? 1 : 0, opacity: inView ? 1 : 0 }}
        transition={{ duration: 1.2, ease: "easeOut" }}
      />
      {shopUrl !== undefined && (
        <Button isExternal={true} text={price} url={shopUrl} />
      )}
      {shopUrl === undefined && <LineReveal renderAs="h4" text={price} />}
    </Container>
  );
};

export default Price;
