import { m } from "framer-motion";
import styled from "styled-components";
import { media } from "../../../utils/styled/responsive";

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  gap: 24px;
`;

export const GoldLine = styled(m.div)`
  width: 2px;
  height: 24px;
  background: ${({ theme }) => theme.gold};
  transform-origin: bottom;
  cursor: default;
  will-change: transform;

  ${media.tablet} {
    height: 32px;
  }

  ${media.desktop} {
    height: 40px;
  }
`;
